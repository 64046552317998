import moment from "moment";

export default class Client {
    id;
    active;
    nativeLanguage;

    salutation;
    firstName;
    lastName;
    userName;
    birthDate
    email;
    mobile;
    phone;

    address;
    postalCode;
    city;
    country;

    image_small;

    internalOnly;
    hasAgenda;
    agendasAllowed = [];
    canOnlyViewAgenda;
    google2faEnabled;

    groups = [];
    settings = [];

    constructor() {
        this.agendasAllowed = [];
        this.canOnlyViewAgenda = false;
        this.google2faEnabled = false;
        this.settings = [];
    }

    static transform = (data, referer = []) => {
        let item = new Client();

        item.id = data.id;
        item.active = data.active;
        item.nativeLanguage = data.nativeLanguage;

        item.salutation = data.salutation;
        item.firstName = data.firstName;
        item.lastName = data.lastName;
        item.userName = data.userName;
        item.birthDate = data.birthDate ? moment(data.birthDate) : null;

        item.email = data.email;
        item.mobile = data.mobile;
        item.phone = data.phone;

        item.address = data.address;
        item.postalCode = data.postalCode;
        item.city = data.city;
        item.country = data.country;

        item.image_small = data.image_small;
        item.lastLogin = data.lastLogin ? moment(data.lastLogin) : null;

        item.internalOnly = !!data.internalOnly;
        item.isSecretary = !!data.isSecretary;
        item.hasAgenda = !!data.hasAgenda;
        item.agendasAllowed = data.agendasAllowed;
        item.canOnlyViewAgenda = !!data.canOnlyViewAgenda
        item.google2faEnabled = data.google2faEnabled

        // item.settings = ClientSetting.transform(data.settings);

        /*if (!UtilArray.inArray(referer, ['group', 'subgroup'])) {
            item.groups = data.groups.map(group => Group.transform(group, ['client']));
        }*/

        return item;
    }

    getFullName = (includeSalutation = true) => ((includeSalutation && this.salutation ? this.salutation : '') + ' ' + this.firstName + ' ' + this.lastName).trim();
    fullName = () => this.salutation + ' ' + this.firstName + ' ' + this.lastName;
    getGroup = (id) => this.groups.find(group => group.id === id);
    setting = (key, def = null) => this.settings[key] ? this.settings[key] : def;
    hasAccessToAgenda = (agendaId) => this.agendasAllowed.length <= 0 || this.agendasAllowed.includes(agendaId);
    hasAccessToSettings = () => !(!!this.canOnlyViewAgenda);
    hasRestrictedAccess = () => this.agendasAllowed.length > 0;
}
