import { t } from 'i18next';
import moment from 'moment';
import { UtilColor, UtilContent, UtilDevice } from '.';

const renderFreeEvent = (element, event) => {
    const { blocked, blockReason, isCanceled } = event.extendedProps;

    if (blocked || ['internal-not-bookable', 'internal-not-bookable-due-settings'].includes(blockReason)) return;

    if (!isCanceled) {
        element.classList.add('fc-canceled-event');
    }

    element.classList.add('fc-free-event');
}

const renderReservedTime = (element, event) => {
    const { reservedMinutesBefore, reservedMinutesAfter } = event.extendedProps;

    if (!reservedMinutesBefore && !reservedMinutesAfter) return;

    element.classList.add('fc-reserved-event');
    element.dataset.mainEvent = event.extendedProps.mainEventId;

    let background = document.createElement('div');
    background.style.zIndex = -1;
    background.style.display = 'block';
    background.classList.add('fc-event-background');

    let color = '#FFFFFF';
    let newColor = event.borderColor;
    background.style.background = 'repeating-linear-gradient(45deg, ' + color + ', ' + color + ' 5px, ' + newColor + ' 5px, ' + newColor + ' 10px)';

    let eventDuration = moment.duration(moment(event.end).diff(event.start)).asMinutes();
    let elHeight = element.getBoundingClientRect().height;

    let timeMargin = 0;
    if (reservedMinutesBefore) {
        let bgBefore = background.cloneNode();

        let height = elHeight * (reservedMinutesBefore / eventDuration)
        bgBefore.style.height = height + 'px';
        element.appendChild(bgBefore);

        timeMargin = height;
    }

    if (reservedMinutesAfter) {
        let bgAfter = background.cloneNode();
        let newHeight = elHeight * (reservedMinutesAfter / eventDuration);
        bgAfter.style.height = newHeight + 'px';
        bgAfter.style.top = (elHeight - newHeight) + 'px';
        element.appendChild(bgAfter);
    }

    let tooltip = '<strong><span class="fa fa-calendar-alt mr-1"></span>' + t('agenda.appointment:reserved:tooltip.appointment') + ':</strong><br />';
    tooltip += moment(event.start).add(reservedMinutesBefore, 'minutes').format('HH:mm') + ' - ' + moment(event.end).subtract(reservedMinutesAfter, 'minutes').format('HH:mm') + '<br/>';
    tooltip += '<strong class="mt-1"><span class="fa fa-ban mr-1"></span>' + t('agenda.appointment:reserved:tooltip.title') + ': </strong><br />';
    if (!!reservedMinutesBefore) {
        tooltip += '<span>' + t('agenda.appointment:reserved:tooltip.before') + ': ' + reservedMinutesBefore + '\'</span><br />';
    }
    if (!!reservedMinutesAfter) {
        tooltip += '<span>' + t('agenda.appointment:reserved:tooltip.after') + ': ' + reservedMinutesAfter + '\'</span><br />';
    }

    let timeEl = element.querySelector('.fc-time');
    if (timeEl) {
        timeEl.innerHTML += '<span class="fa fa-info-circle ml-1"></span>';
        timeEl.setAttribute('data-tip', tooltip);
        timeEl.setAttribute('data-html', true);

        if (timeMargin > 0) {
            timeEl.style.marginTop = timeMargin + 'px';
        }
    }
}

const renderBackground = (element, event) => {
    const { isCanceled, isDummy, hasReservedMinutes } = event.extendedProps;

    let background = document.createElement('div');
    background.style.zIndex = -1;
    background.classList.add('fc-event-background');

    if (isCanceled) {
        let color = '#ffffff';
        let newColor = '#f05050';
        background.style.background = 'repeating-linear-gradient(45deg, ' + color + ', ' + color + ' 5px, ' + newColor + ' 5px, ' + newColor + ' 10px)';

        element.style.border = 0;
    } else if (isDummy) {
        let color = '#ffffff';
        let newColor = '#aaaaaa';
        background.style.background = 'repeating-linear-gradient(45deg, ' + color + ', ' + color + ' 5px, ' + newColor + ' 5px, ' + newColor + ' 10px)';

        element.classList.add('fc-free-event');
    } else if (!hasReservedMinutes) {
        background.style.backgroundColor = event.borderColor;
    } else {
        background.style.backgroundColor = event.borderColor;
    }

    element.appendChild(background);
}

const renderStatusIndicator = (element, event, props) => {
    const { appointments } = event.extendedProps;

    if (appointments.length !== 1) return;

    let appointment = appointments[0];

    const { statuses } = props.schemaData;

    let statusBackgroundColor = null;
    let statusTextColor = '#000000';
    let statusTitle = null;
    let statusCode = null;

    if (appointment.appointmentStatus) {
        let status = statuses[appointment.appointmentStatus];
        if (status) {
            statusBackgroundColor = status ? status.backgroundColor : statusBackgroundColor;
            statusTextColor = status ? status.textColor : statusTextColor;
            statusTitle = status ? status.title : statusTitle;
            statusCode = status ? status.code : statusCode;
        }
    }

    if (!statusBackgroundColor) return;

    let nrAgendas = 12;
    let contentWrapperEl = document.querySelector('.content-wrapper');
    if (contentWrapperEl) {
        let contentWidth = contentWrapperEl.offsetWidth - 60;
        nrAgendas = contentWidth / 100;
    }

    let { resources } = props;
    let hideEndTime = resources && resources.length > nrAgendas;
    if (hideEndTime) {
        let timeEl = element.querySelector('.fc-time');
        if (timeEl && 'start' in timeEl.dataset) {
            timeEl.querySelector('span').innerText = timeEl.dataset.start;
        }
    }

    let status = document.createElement('div');
    status.classList.add('fc-status');
    status.style.backgroundColor = statusBackgroundColor;
    status.setAttribute('data-tip', statusTitle);

    if (statusTextColor) {
        status.style.color = statusTextColor;
    }

    if (statusCode) {
        let statusCodeEl = document.createElement('span');
        statusCodeEl.classList.add('fc-status-code');
        statusCodeEl.textContent = statusCode
        status.innerHTML = statusCodeEl.outerHTML;
    }

    element.querySelector('.fc-time').appendChild(status);
}

const renderContextMenu = (element, callback) => {
    let contextMenuEl = document.createElement('div');
    contextMenuEl.classList.add('context-menu');
    element.innerHTML += contextMenuEl.outerHTML;

    if (UtilDevice.isTouchEnabled()) {
        let ellipsisEl = document.createElement('span');
        ellipsisEl.classList.add('fas', 'fa-ellipsis-h', 'fa-sm');

        let contextElLink = document.createElement('a');
        contextElLink.classList.add('btn', 'btn-link', 'btn-context-menu');
        contextElLink.innerHTML = ellipsisEl.outerHTML;
        contextElLink.style.position = 'absolute';
        contextElLink.style.right = 0;
        contextElLink.style.bottom = 0;
        contextElLink.style.zIndex = 2;
        contextElLink.style.padding = '0 10px 10px 0';
        contextElLink.style.width = '15px';
        contextElLink.style.height = '15px';

        element.innerHTML += contextElLink.outerHTML;

        element.querySelector('.btn-context-menu').addEventListener('click', callback);
    } else {
        element.addEventListener('contextmenu', callback);
    }
}

const renderAppointments = (element, event) => {
    const { appointments, isHomeVisit, blockReason } = event.extendedProps;

    if (blockReason === 'reserved') return;

    let titleEl = element.querySelector('.fc-title');
    if (!titleEl) return;

    let value = titleEl.innerHTML;

    titleEl.innerHTML = '';
    if (isHomeVisit && value.includes('[')) {
        titleEl.innerHTML = value + '<br />';
        titleEl.style.float = 'none';
    }

    if (appointments.length > 1) {
        titleEl.classList.add('calendar-appointment-link');
        titleEl.innerHTML += t('agenda.appointment:slot-indator-multiple-appointments', { count: appointments.length })

    } else {
        appointments.forEach(appointment => {
            renderAppointmentItem(titleEl, appointment);
        });
    }
}

const renderAppointmentItem = (element, appointment) => {
    let { id, patient, appointmentData, repeatSequence } = appointment;

    let patientName = patient.name;
    if (repeatSequence) patientName += ' [' + repeatSequence + ']';

    element.classList.add('calendar-appointment-link');
    element.dataset.id = id;
    element.innerHTML += patientName;

    let contentEl = closest(element, 'fc-content');
    if (!contentEl) return;

    let labels = appointmentData.length > 0 ? appointmentData : [];

    let eventTextEl = document.createElement('div');
    eventTextEl.classList.add('fc-text', 'calendar-appointment-link');
    eventTextEl.dataset.appointmentId = id;
    eventTextEl.innerHTML = labels.join(', ');
    contentEl.appendChild(eventTextEl);
}

const renderBlockedTitle = (element) => {
    let titleEl = element.querySelector('.fc-title');
    if (!titleEl) return;

    titleEl.innerHTML = '<i class="fa fa-ban mr-1"></i>' + titleEl.innerHTML;
}

const renderLockedIndicator = (element, event) => {
    const { locked, lockedBy } = event.extendedProps;

    if (!locked) return;

    let beatBalEl = document.createElement('span');
    beatBalEl.classList.add('ball-beat', 'ball-small');

    let lockedByMessage = 'Slot wordt op dit moment ingeboekt';
    if (lockedBy) {
        lockedByMessage += ' door: ' + lockedBy;
    }
    beatBalEl.dataset.message = lockedByMessage;

    for (let i = 0; i < 3; i++) {
        let ballEl = document.createElement('div');
        ballEl.style.background = UtilColor.contrastBlackOrWhite(event.borderColor);
        beatBalEl.appendChild(ballEl);
    }

    let timeEl = element.querySelector('.fc-time');
    timeEl.querySelector('span').innerHTML += beatBalEl.outerHTML;
    timeEl.dataset.start = ' ... ' + timeEl.dataset.start;
    element.classList.add('fc-locked');
}

const renderNoteIndicator = (element, event) => {
    if (!event.extendedProps.isNote) return;

    let patientIndicator = null;
    if (event.extendedProps.patient.id) {
        patientIndicator = document.createElement('span');
        patientIndicator.classList.add('fa', 'fa-user', 'ml-1');
        patientIndicator.dataset.tip = event.extendedProps.patient.name;
    }

    let timeEl = element.querySelector('.fc-time span');

    if (timeEl) {
        timeEl.innerHTML += ' (' + t('agenda.note:title').toLowerCase() + ')';
        if (patientIndicator) {
            timeEl.innerHTML += patientIndicator.outerHTML;
        }
    } else {
        timeEl = element.querySelector('.fc-content');
        if (timeEl) {
            let currentValue = timeEl.innerHTML;
            timeEl.innerHTML = '<span>' + t('agenda.note:title').toLowerCase() + '</span>';
            if (patientIndicator) {
                timeEl.innerHTML += patientIndicator.outerHTML;
            }
            timeEl.innerHTML += '<br />' + currentValue;
        }
    }

    if (event.title !== event.extendedProps.fullNote) {
        let _noteEnterTimeout = null;
        element.addEventListener('mouseenter', () => {
            _noteEnterTimeout = setTimeout(() => element.querySelector('.fc-title').innerHTML = UtilContent.nl2br(event.extendedProps.fullNote), 250)
        });

        element.addEventListener('mouseleave', () => {
            if (_noteEnterTimeout) {
                clearTimeout(_noteEnterTimeout);
            }
            element.querySelector('.fc-title').innerHTML = UtilContent.nl2br(event.title);
        });
    }
}

const renderCanceledIndicator = (element, event) => {
    if (!event.extendedProps.isCanceled) return;

    let timeEl = element.querySelector('.fc-time span');
    if (timeEl) {
        timeEl.innerHTML += ' (' + t('agenda.appointment:canceled').toLowerCase() + ')';
    } else {
        timeEl = element.querySelector('.fc-content');
        if (timeEl) {
            let currentValue = timeEl.innerHTML;
            timeEl.innerHTML = '<span>' + t('agenda.appointment:canceled').toLowerCase() + '</span><br />' + currentValue
        }
    }
}

const renderSlotNotBookable = (element, event) => {
    if (event.extendedProps.blockReason !== 'internal-not-bookable-due-settings') return;

    element.classList.add('fc-slot-lock');

    let indicatorEl = document.createElement('span');
    indicatorEl.classList.add('fa', 'fa-lock', 'mr-1');

    let timeEl = element.querySelector('.fc-time');
    if (timeEl) {
        timeEl.querySelector('span').innerHTML = indicatorEl.outerHTML + timeEl.querySelector('span').innerHTML;
    }
}

const renderExceptionIndicator = (element, event) => {
    if (!event.extendedProps.isException) return;

    element.classList.add('fc-exception');

    let exceptionEl = document.createElement('span');
    exceptionEl.classList.add('ml-1');
    if (event.extendedProps.extra) {
        exceptionEl.innerHTML = t('agenda.myagenda:exception-extra-slot.indicator');
    } else {
        exceptionEl.innerHTML = t('agenda.myagenda:exception.indicator');
    }

    let timeEl = element.querySelector('.fc-time');
    if (timeEl) {
        if (event.extendedProps.extra) {
            timeEl.dataset.start = t('agenda.myagenda:exception-extra-slot.indicator') + ' ' + timeEl.dataset.start;
        } else {
            timeEl.dataset.start = t('agenda.myagenda:exception.indicator') + ' ' + timeEl.dataset.start;
        }

        timeEl.querySelector('span').appendChild(exceptionEl);
    }
}

const renderPriorityBreakHourIndicator = (element, event, settings) => {
    if (!settings.agendaShowPriorityHours) return;

    const { priority } = event.extendedProps;

    if (priority) {
        let priorityEl = document.createElement('span');
        priorityEl.classList.add('ml-1');
        priorityEl.innerHTML = '[' + priority + ']'

        let timeEl = element.querySelector('.fc-time');
        if (timeEl) {
            timeEl.dataset.start = '[' + priority + '] ' + timeEl.dataset.start;
            timeEl.querySelector('span').appendChild(priorityEl);
        }
    }

    if (event.extendedProps.isBreakHour) {
        element.classList.add('fc-breakhour');
    }
}

const renderSubcategoryLabel = (element, event, props) => {
    if (!props.clientSettings.agendaShowSubcategoryLabels) return;

    const { appointments, blockReason } = event.extendedProps;

    if (appointments.length !== 1 || blockReason === 'reserved') return;

    appointments.forEach(appointment => {
        if (!appointment.subcategoryId) return;

        let subcategory = props.schemaData.subcategories[appointment.subcategoryId] ? props.schemaData.subcategories[appointment.subcategoryId] : null;
        if (!subcategory) return;

        let subcategoryLabelEl = document.createElement('span');
        subcategoryLabelEl.classList.add('fc-subcateogry-label');
        subcategoryLabelEl.textContent = subcategory.name;
        subcategoryLabelEl.style.backgroundColor = subcategory.bookedSlotBackground;
        subcategoryLabelEl.style.color = UtilColor.contrastBlackOrWhite(subcategory.bookedSlotBackground);

        element.querySelector('.fc-content').appendChild(subcategoryLabelEl);
    });
}

const renderBingliIndicator = (element, event, props) => {
    const { appointments } = event.extendedProps;

    if (appointments.length !== 1) return;

    let appointment = appointments[0];
    if (appointment.bingliState !== 'finished') return;

    let nrAgendas = 12;
    let contentWrapperEl = document.querySelector('.content-wrapper');
    if (contentWrapperEl) {
        let contentWidth = contentWrapperEl.offsetWidth - 60;
        nrAgendas = contentWidth / 100;
    }

    let { resources } = props;
    let hideEndTime = resources && resources.length > nrAgendas;
    if (hideEndTime) {
        let timeEl = element.querySelector('.fc-time');
        if (timeEl && 'start' in timeEl.dataset) {
            timeEl.querySelector('span').innerText = timeEl.dataset.start;
        }
    }

    let status = document.createElement('div');
    status.classList.add('fc-status', 'fc-tooltip', 'float-right');
    status.dataset.tip = t('agenda.appointment:bingli.indicator.tooltip');
    status.style.backgroundColor = '#27c24c';
    status.style.color = '#ffffff';

    let statusCodeEl = document.createElement('span');
    statusCodeEl.classList.add('fc-status-code');
    statusCodeEl.textContent = 'B'
    status.innerHTML = statusCodeEl.outerHTML;

    element.querySelector('.fc-time').appendChild(status);
}
const renderAppointmentBookedTooltip = (element, event, props) => {
    let { appointmentsBookedOnSlot, maximumAppointments, initialAppointmentsPerSlot } = event.extendedProps;

    if (appointmentsBookedOnSlot > initialAppointmentsPerSlot) return;
    if (appointmentsBookedOnSlot === undefined || maximumAppointments <= 1) return;

    let titleEl = element.querySelector('.fc-title');
    if (!titleEl) return;

    titleEl.setAttribute('data-tip', t('agenda.appointment:slot-booked-tooltip', { booked: appointmentsBookedOnSlot, maximum: initialAppointmentsPerSlot }));
}

const renderExceptionTooltip = (element, event) => {
    if (!element.classList.contains('fc-exception')) return;

    let timeEl = element.querySelector('.fc-time');
    if (!timeEl) return;

    timeEl.setAttribute('data-tip', t('agenda.myagenda:exception'));
}

const renderLockedTooltip = (element, event) => {
    if (!element.classList.contains('fc-locked')) return;

    let ballBeatEl = element.querySelector('.ball-beat');
    if (!ballBeatEl) return;

    ballBeatEl.setAttribute('data-tip', ballBeatEl.dataset.message);

    if (element.classList.contains('fc-short')) {
        let timeEl = element.querySelector('.fc-time');
        if (timeEl) {
            timeEl.setAttribute('data-tip', ballBeatEl.dataset.message);
        }
    }
}

const renderSlotLockedTooltip = (element, event) => {
    if (!element.classList.contains('fc-slot-lock')) return;

    let timeEl = element.querySelector('.fc-time');
    if (!timeEl) return;

    timeEl.setAttribute('data-tip', t('agenda.myagenda:slot-locked-tooltip'));
}

const renderPaymentIcon = (element, event) => {
    const { appointments } = event.extendedProps;

    if (appointments.length !== 1) return;

    let { payment } = appointments[0];

    if (!payment || !payment.id) return;

    let paymentEl = document.createElement('div');
    paymentEl.classList.add('fa', 'fa-euro-sign', 'ml-1', ('text-' + t('settings.payment:status:color:' + payment.status)));
    paymentEl.setAttribute('data-tip', t('settings.payment:status:label:' + payment.status));

    element.querySelector('.fc-time').appendChild(paymentEl);
}

const closest = (target, tag) => {
    if (target.classList.contains(tag)) return target;

    if (!target.parentElement) return null;

    return closest(target.parentElement, tag);
};

const setEventOrder = (event1, event2) => {
    let event1HasAppointment = event1.appointments && event1.appointments.length > 0;
    let event2HasAppointment = event2.appointments && event2.appointments.length > 0;

    if (!event1HasAppointment || !event2HasAppointment) {
        return event1.slotId < event2.slotId ? -1 : 1;
    }

    return event1HasAppointment < event2HasAppointment ? 1 : -1
};

const resetTooltip = () => document.querySelectorAll('[data-id="tooltip"]').forEach(el => el.classList.remove('show'));

export const UtilCalendar = {
    renderFreeEvent,
    renderReservedTime,
    renderBackground,
    renderStatusIndicator,
    renderContextMenu,
    renderExceptionIndicator,
    renderPriorityBreakHourIndicator,
    renderSlotNotBookable,
    renderBlockedTitle,
    renderLockedIndicator,
    renderNoteIndicator,
    renderCanceledIndicator,
    renderSubcategoryLabel,
    renderAppointments,
    renderAppointmentItem,
    renderBingliIndicator,
    renderAppointmentBookedTooltip,
    renderExceptionTooltip,
    renderLockedTooltip,
    renderSlotLockedTooltip,
    renderPaymentIcon,
    setEventOrder,
    resetTooltip,
    closest
};