import React from 'react';
import { t } from 'i18next';
import Calendar from 'react-calendar/dist/entry.nostyle';
import moment from 'moment';
import routes from '../../../routing/routes';
import { reverse } from 'named-urls';
import { ApiScheduleService } from '../../../services/api/schedule.service';
import { connect } from 'react-redux';

class SidebarCalendar extends React.Component {
    constructor(props) {
        super(props);

        this._isMounted = false;

        this.state = {
            type: 'agenda',
            id: props.client.id,
            date: new Date(),
            selectedDate: null,
            monthLoaded: null, // moment().format('YYYY-MM'),
            slotCount: {}
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.bindCalendar();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        this.tileContent = this.tileContent.bind(this);

        return (
            <Calendar
                value={this.getDate()}
                tileContent={this.tileContent}
                onClickDay={this.onClick}
                showNeighboringMonth={true}
                onActiveDateChange={this.onActiveDateChange}
                onClickMonth={this.onClickMonth}
                showWeekNumbers={this.props.client.settings ? this.props.client.settings.showWeekNumbers : false}
                prev2Label={false}
                next2Label={false}
                minDetail="year"
            />
        )
    }

    getDate = () => this.props.settings.selectedCalendarDate ? new Date(this.props.settings.selectedCalendarDate) : (this.state.selectedDate ? new Date(this.state.selectedDate) : new Date());

    bindCalendar = () => {
        document.querySelectorAll('button.react-calendar__tile').forEach(tile => {
            tile.addEventListener('mouseenter', (e) => {
                document.querySelectorAll('.react-calendar-tooltip').forEach(tooltip => tooltip.classList.add('hidden'));
                let tooltip = e.target.querySelector('.react-calendar-tooltip');
                if (tooltip) tooltip.classList.remove('hidden');
            });

            tile.addEventListener('mouseleave', (e) => {
                let tooltip = e.target.querySelector('.react-calendar-tooltip');
                if (tooltip) tooltip.classList.add('hidden');
            });
        });

        document.addEventListener('reloadSmallCalendar', (e) => this.load(moment(e.detail.date)));

        document.addEventListener('caseChanged', (e) => {
            let { id, type } = e.detail;

            if ((this.state.slotCount && this.state.slotCount.length > 0) && this.state.id === id && this.state.type === type) {
                return;
            }

            if (this._isMounted) {
                this.setState({ id: id, type: type });
            }
            this.load(this.state.date, id, type);
        });
    }

    tileContent = ({ date, view }) => {
        if (view !== 'month' || !this.state.slotCount || Object.keys(this.state.slotCount) <= 0) {
            return '';
        }

        date = moment(date).format('YYYY-MM-DD');

        const count = this.state.slotCount[date];

        if (count === undefined) {
            return '';
        }

        let availability = '';
        if (count === 0) {
            availability = 'full';
        } else if (count <= 5) {
            availability = 'almost-full';
        }

        return (
            <React.Fragment>
                <div className="react-calendar-tooltip">{t('common:freeSlots', { count: count })}</div>
                <div className={'react-calendar-availability react-calendar-availability--' + availability}></div>
            </React.Fragment>
        );
    }

    onClick = (value) => this.setState({ selectedDate: new Date(moment(value).format('YYYY-MM-DD')) }, () => {
        let link = null;
        let params = { date: moment(value).format('YYYY-MM-DD') };

        switch (this.state.type) {
            case 'subgroup':
                params.subgroup = this.state.id;
                link = reverse(routes.agenda.subgroup, params);
                break;
            case 'group':
                link = reverse(routes.agenda.group, params);
                break;
            case 'homevisits':
                params.client = 'all';
                link = reverse(routes.homevisits.overview, params);
                break;
            case 'agenda':
            default:
                let clientId = this.state.id;
                if (!this.props.loggedInClient.hasAgenda) {
                    clientId = this.props.activeClient.id;
                }
                params.client = clientId;
                link = reverse(routes.agenda.client, params);
                break;
        }

        this.props.history.push(link);
    });


    onActiveDateChange = (info) => this.load(info.activeStartDate);
    onClickMonth = (value) => this.load(value);

    load = (startDate, id, type) => {
        if (id === undefined) {
            id = this.state.id;
        }

        if (type === undefined) {
            type = this.state.type;
        }

        if ((type === 'subgroup' || type === 'group') && this._isMounted) {
            this.setState({ slotCount: {} });
        }

        if (!this.doReload(startDate, id, type)) return;

        ApiScheduleService.getFreeSlotCount(
            moment(startDate).startOf('month').startOf('week').format('YYYY-MM-DD'),
            moment(startDate).endOf('month').endOf('week').format('YYYY-MM-DD'),
            id,
            type
        )
            .then(result => {
                if (!this._isMounted) return;
                return this.setState({ slotCount: result, monthLoaded: moment(startDate).format('YYYY-MM') });
            });
    }

    doReload = (date, id, type) => {
        // Temp disable for (sub)group overviews
        if (type === 'subgroup' || type === 'group') {
            return false;
        }

        // Do not reload if date + type + id is the same
        if (
            moment(date).format('YYYY-MM') === moment(this.state.monthLoaded).format('YYYY-MM') &&
            type === this.state.type &&
            id === this.state.id
        ) {
            return false;
        }

        return true;
    }
}

const mapStateToProps = state => ({
    activeClient: state.client ? state.client.active : null,
    loggedInClient: state.client ? state.client.logged_in : null,
    settings: state.settings
});

export default connect(mapStateToProps)(SidebarCalendar);